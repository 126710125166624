import { useSupply } from "../hooks/useSupply"
import { EtherUtils } from "../utils/ether.utils"

export const Supply = () => {
  const { totalSupply, circulatingSupply } = useSupply()

  return (
    <div className="flex gap-6">
      <div className="flex flex-col gap-0.5 text-white text-xs lg:text-sm">
        <span className="font-bold tracking-wide">Total Supply</span>
        <div className="bg-white w-full h-[1px]"></div>
        <span>
          {totalSupply ?
            `${EtherUtils.prettyAmount(totalSupply, false)} WSI` :
            <div className="w-full h-4 mt-1 bg-secondary/75 animate-pulse"></div>
          }
        </span>
      </div>
      <div className="flex flex-col gap-0.5 text-white text-xs lg:text-sm">
        <span className="font-bold tracking-wide">Circulating Supply</span>
        <div className="bg-white w-full h-[1px]"></div>
        <span>
          {circulatingSupply ?
            `${EtherUtils.prettyAmount(circulatingSupply || "", false)} WSI` :
            <div className="w-full h-4 mt-1 bg-secondary/75 animate-pulse"></div>
          }
        </span>
      </div>
    </div>
  )
}