import { Trans } from "react-i18next";
import { useTranslation } from "../app/i18n/client";
import FaqEntry from "./FaqEntry";
import { LinkText } from "./LinkText";

interface Props {
  entries: string[]
}

const FaqSection = ({ entries }: Props) => {
  const { t } = useTranslation(undefined, 'faq')

  return (
    <div className='flex flex-col justify-center items-center gap-4 w-full h-max p-8'>
      <h2 className='text-3xl text-primary font-bold tracking-wide my-4 place-self-start'>{t('title')}</h2>

      {entries.map(entry => (
        <FaqEntry key={entry} title={t(`${entry}.title`)}>
          <span className='text-sm'>
            <Trans t={t} i18nKey={`${entry}.content`} components={{
              custom_link: <LinkText href={t(`${entry}.link`)} />,
              ul: <ul className='list-disc list-inside' />,
              li: <li />
            }} />
          </span>
        </FaqEntry>
      ))}
    </div>
  )
}

export default FaqSection