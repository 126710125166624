import classNames from "classnames"

interface Props {
  id?: string,
  className?: string,
  children?: any
}

export const Section = ({ id, className, children }: Props) => {
  return (
    <div id={id} className={classNames(
      'relative w-full h-max',
      className
    )}>
      {children}
    </div>
  )
}