import classNames from "classnames"
import { forwardRef } from "react"
import { Spinner } from "./Spinner"

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE
}

export enum ButtonStyle {
  OUTLINED,
  NORMAL,
  ALT,
  ROUNDED
}

interface Props {
  ref?: any
  className?: string,
  children: any
  size: ButtonSize
  style?: ButtonStyle
  onClick?: any
  disabled?: boolean,
  isLoading?: boolean
}

const Button = (props: Props) => {
  const { ref, className, children, size, style, onClick, disabled, isLoading } = props

  const onButtonClick = () => {
    if (disabled) {
      return
    }

    if (onClick && typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <button disabled={disabled} ref={ref} className={classNames(
      'flex justify-center items-center rounded h-max text-sm lg:text-xl cursor-pointer transition-all',
      'disabled:opacity-50 disabled:pointer-events-none',
      {
        'w-max': !className?.includes('w-'),
        'px-5 py-2 lg:!text-base': size === ButtonSize.SMALL,
        'px-8 py-3': size === ButtonSize.MEDIUM,
        'bg-primary text-white border-2 border-primary hover:bg-white hover:text-primary hover:border-primary': style === undefined || style === ButtonStyle.NORMAL || style === ButtonStyle.ROUNDED,
        'bg-transparent text-white text-sm lg:text-base font-semibold border-0.5 border-white hover:bg-white hover:text-primary hover:border-white': style === ButtonStyle.OUTLINED,
        'bg-primary/70 hover:bg-primary/70 hover:text-white cursor-not-allowed': disabled && (style === ButtonStyle.NORMAL || style === ButtonStyle.OUTLINED),
        'bg-[#125b98] text-white hover:bg-white hover:text-primary hover:border-primary': style === ButtonStyle.ALT,
        'bg-[#125b98]/50 text-white hover:bg-[#125b98]/50 hover:text-white cursor-not-allowed': disabled && style === ButtonStyle.ALT,
        'rounded-3xl px-10 font-semibold ': style === ButtonStyle.ROUNDED,
        'pr-0 gap-3': isLoading
      },
      className
    )} onClick={onButtonClick}>
      {children}
      {isLoading && <Spinner />}
    </button>
  )
}

const component = forwardRef((props: Props, ref) => <Button {...props} />)
component.displayName = 'Button'

export default component
