import { useEffect, useState } from "react"

export const useRefresh = (interval?: number) => {
  const [manualRefresh, setManualRefresh] = useState(0)
  const [refresh, setRefresh] = useState(0)

  const doManualRefresh = () => {
    setManualRefresh(refresh + 1)
  }

  const doRefresh = () => {
    setRefresh(refresh + 1)
  }

  useEffect(() => {
    if (!interval) {
      return
    }

    setTimeout(() => {
      doRefresh()
    }, interval)
  }, [refresh])

  return {
    shouldManualRefresh: manualRefresh,
    shouldRefresh: refresh,
    doManualRefresh
  }
}