import { useState, useEffect } from "react"
import { useRefresh } from "./useRefresh"
import axios from "axios"

export const useSupply = () => {
  const [totalSupply, setTotalSupply] = useState()
  const [circulatingSupply, setCirculatingSupply] = useState()

  const {
    shouldRefresh
  } = useRefresh(60 * 1000)

  useEffect(() => {
    getCirculatingSupply().then(setCirculatingSupply)
    getTotalSupply().then(setTotalSupply)
  }, [shouldRefresh])

  const getCirculatingSupply = async () => {
    const res = await axios.get('/api/circulatingSupply')

    return res.data.toString()
  }

  const getTotalSupply = async () => {
    const res = await axios.get('/api/totalSupply')

    return res.data.toString()
  }

  return {
    circulatingSupply,
    totalSupply
  }
}
