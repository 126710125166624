import classNames from "classnames"

interface Props {
  maxWidth?: number,
  className?: string,
  children?: any
}

export const Boxed = ({ maxWidth, className, children }: Props) => {
  return (
    <div className={classNames(
      'relative w-full h-max mx-auto flex flex-col justify-center items-center',
      className
    )} style={{
      maxWidth: `${maxWidth || 1400}px`
    }}>
      {children}
    </div>
  )
}