import classNames from "classnames"
import { useState } from "react"
import { MdArrowCircleDown, MdArrowCircleUp } from "react-icons/md"

interface Props {
  title: string,
  children?: any
}

const FaqEntry = (props: Props) => {
  const { title, children } = props

  const [open, setOpen] = useState(false)

  return (
    <div className='flex flex-col gap-4 w-full border-b-2 last:border-b-0 border-slate-300 p-4 h-max transition-all'>
      <div className='flex justify-between gap-4 cursor-pointer group' onClick={() => setOpen(!open)}>
        <span className={classNames(
          'text-black font-semibold',
          {
            'text-primary': open
          }
        )}>{title}</span>
        {open ?
          <MdArrowCircleUp className={classNames(
            'text-xl lg:text-2xl flex-shrink-0',
            {
              'text-primary': open
            }
          )} /> :
          <MdArrowCircleDown className={classNames(
            'text-xl lg:text-2xl flex-shrink-0',
            {
              'text-primary': open
            }
          )} />
        }
      </div>
      <div className={classNames(
        {
          'hidden': !open
        }
      )}>{children}</div>
    </div>
  )
}

export default FaqEntry