import { BigNumber, BigNumberish } from 'ethers'
import { formatEther as ethersFormatEther } from 'ethers/lib/utils'

export class EtherUtils {

  static formatEther(amount?: BigNumberish, digits: number = 18, pretty: boolean = false): string {
    if (!amount || BigNumber.from(amount).eq(0)) {
      return '0'
    }

    // TODO: add delimiter " ' "

    if (digits === 0) {
      const res = ethersFormatEther(amount)
      const str = res.substring(0, res.indexOf('.'))

      return this.prettyAmount(str)
    }

    const res = ethersFormatEther(amount)

    if (pretty) {
      const str = res.substring(0, res.indexOf('.') + 1 + digits)

      return this.prettyAmount(str)
    }

    return res.substring(0, res.indexOf('.') + 1 + digits)
  }

  static prettyAmount = (amount: string, decimals: boolean = true): string => {
    const [left, right] = amount.split('.')
    const leftPretty = parseInt(left).toLocaleString('it-CH')

    if (!right || !decimals) {
      return `${leftPretty}`
    }

    return `${leftPretty}.${right}`
  }

  static truncateAddress = (address: string, length: number = 6): string => {
    const charsStart = (length / 2) + 2
    const charsEnd = (length / 2)

    return `${address.substring(0, charsStart)}...${address.substring(address.length - charsEnd)}`
  }

  static min = (amount0: BigNumber, amount1: BigNumber): BigNumber => {
    return amount0.lt(amount1) ? amount0 : amount1
  }

  static isSameAddress = (a: string, b: string): boolean => {
    return a.toLowerCase() === b.toLowerCase()
  }

}