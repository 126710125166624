import { Trans } from "react-i18next"
import { useTranslation } from '../app/i18n/client'
import { useState } from "react"
import Button, { ButtonSize, ButtonStyle } from "./Button"
import FaqSection from "./FaqSection"
import Footer from "./Footer"
import Head from "./Head"
import Header from "./Header"
import LinkComponent from "./LinkComponent"
import Sidebar from "./Sidebar"
import { Boxed } from "./Boxed"
import { Section } from "./Section"
import classNames from "classnames"
import { Supply } from "./Supply"

interface Props {
  title: string;
  children: any;
  showFooter?: boolean;
  showHero?: boolean;
  hideBackground?: boolean,
  boxed?: boolean,
  className?: string,
  faqEntries?: string[],
  head?: React.ReactElement
}

const Layout = (props: Props) => {
  const { title, children, showFooter, showHero, hideBackground, boxed, faqEntries, head } = props;
  const { t } = useTranslation(undefined, "common");

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className={classNames(
      'flex flex-col justify-center items-center w-full h-full',
      props.className
    )}>
      {head ? head : <Head title={title} />}

      {!hideBackground && <>
        <div className="z-10 w-full h-full absolute top-0 left-0 bg-overlay bg-no-repeat bg-overlay-mobile md:bg-overlay-handheld lg:bg-auto"></div>
        <div className="w-full h-full absolute top-0 left-0 bg-radial"></div>
      </>}

      <div className="sticky top-0 z-30 w-full bg-white">
        <Header setSidebarOpen={setSidebarOpen}>
          {children[0]}
        </Header>
      </div>

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
        {children[0]}
      </Sidebar>

      <div className={classNames(
        'z-20 relative w-full',
        {
          'max-w-[1600px]': boxed
        }
      )}>
        {showHero ? (
          <main className="w-full h-max grid grid-cols-1 lg:grid-cols-2 place-items-center px-8 py-20 gap-20 lg:gap-10">
            <div className="w-full flex flex-col justify-center items-center text-center lg:text-left lg:items-start md:max-w-[525px] gap-4 lg:justify-self-end">
              <span className="text-xl lg:text-4xl text-white font-bold">
                <Trans
                  t={t}
                  i18nKey="headline"
                  components={{
                    bigger: (
                      <span className="text-[30px] lg:text-[40px] text-outlined" />
                    ),
                  }}
                />
              </span>
              <div className="flex flex-col lg:flex-row justify-start items-center gap-6 mt-4">
                <LinkComponent
                  target="_blank"
                  href="https://pancakeswap.finance/swap?outputCurrency=0x837A130aED114300Bab4f9f1F4f500682f7efd48"
                >
                  <Button size={ButtonSize.MEDIUM} style={ButtonStyle.OUTLINED}>
                    PancakeSwap
                  </Button>
                </LinkComponent>
                <Supply />
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full">
              {children[1]}
              <div className="w-full flex justify-center items-center gap-6 lg:gap-8">
                <img
                  className="h-5 lg:h-10"
                  alt="Filecoin Logo"
                  src="/assets/img/logo-filecoin.svg"
                />
                <img
                  className="h-5 lg:h-10"
                  alt="Siacoin Logo"
                  src="/assets/img/logo-siacoin.svg"
                />
                <img
                  className="h-5 lg:h-10"
                  alt="Storj Logo"
                  src="/assets/img/logo-storj.svg"
                />
                <span className="w-max text-sm lg:text-lg text-white font-semibold">
                  Powered Web 3.0
                  <br />
                  Networks by WeSendit
                </span>
              </div>
            </div>
          </main>
        ) : (
          <main className="w-full h-max">{children[1]}</main>
        )}
      </div>

      {children[2]}

      {faqEntries && faqEntries.length > 0 && (
        <Section className="bg-white">
          <Boxed>
            <FaqSection entries={faqEntries} />
          </Boxed>
        </Section>
      )}

      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
