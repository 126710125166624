interface Props {
  title: string
}

const Head = (props: Props) => {
  const { title } = props

  return (
    <>
      <title>{title}</title>
      <meta name="description" content="WeSendit is the 1st. Gateway to the world of decentralised Storage Networks &#8211; WeSendit Token &#8211; We protect your data and personal information with easy access to decentralized transfer &amp; storage solutions." />
      <link rel="icon" href="/assets/img/favicon.png" />
    </>
  )
}

export default Head