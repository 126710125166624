import Link from "next/link"
import { MdArrowDropDown, MdClose } from "react-icons/md"
import { getMenuItems } from './Header'
import classNames from "classnames"
import { useTranslation } from "../app/i18n/client"
import { createElement } from "react"

interface Props {
  children: any,
  sidebarOpen: boolean,
  setSidebarOpen: Function
}

const Sidebar = (props: Props) => {
  const { children, sidebarOpen, setSidebarOpen } = props
  const { t } = useTranslation(undefined, 'header')
  const items = getMenuItems()

  return (
    <div className={classNames(
      'flex xl:hidden flex-col fixed top-0 right-0 w-full h-full bg-white z-50',
      {
        'hidden': !sidebarOpen
      }
    )}>
      <div className="flex justify-between items-center bg-light-blue px-8 p-5">
        <Link href="/">
          <img
            className="w-[150px]"
            alt="WeSendit Logo"
            src="/assets/img/logo-new.svg"
          />
        </Link>
        <MdClose className='text-3xl z-50' onClick={() => setSidebarOpen(false)} />
      </div>
      <div className='flex flex-col overflow-x-auto pb-16'>
        {items.map(item => (
          <div key={item.label}>
            <div className="flex gap-1 items-center border-b border-light-blue py-5">
              {item.href ?
                <Link href={item.href} target={item.target}>
                  <span className='text-lg font-semibold text-primary cursor-pointer hover:text-primary-shade transition-all pl-8 pr-0'>
                    {t(item.label)}
                  </span>
                </Link>
                :
                <span className='text-lg font-semibold text-primary cursor-pointer hover:text-primary-shade transition-all pl-8 pr-0'>
                  {t(item.label)}
                </span>
              }
              {item.children && <MdArrowDropDown className="text-2xl" />}
            </div>
            <div className="absolute top-0 h-10 w-full"></div>
            {item.children && (
              <div className="flex flex-col w-full gap-3 bg-white text-lg font-semibold px-4 pt-4">
                {item.children?.map(child => (
                  <Link href={child.href || ''} target={child.target} key={child.label}>
                    <div className="flex gap-6 items-center cursor-pointer w-full p-4 hover:bg-light-blue transition-all rounded-2xl">
                      {child.icon &&
                        <div className="text-primary text-2xl">
                          {typeof child.icon === 'string' ?
                            <img src={child.icon} />
                            :
                            createElement(child.icon)
                          }
                        </div>
                      }
                      <div className="flex flex-col gap-1">
                        <span className="text-primary text-sm">{child.label}</span>
                        <span className="text-slate-400 text-xs">{child.description}</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center absolute bottom-0 mx-auto left-0 right-0 bg-light-blue h-16">
        {children}
      </div>
    </div>
  )
}

export default Sidebar