import React from 'react'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'

const LinkComponent = ({ children, skipLocaleHandling, ...rest }: any) => {
  const path = usePathname()
  const searchParams = useSearchParams()
  const locale = rest.locale || searchParams?.get('locale') || ''

  let href = rest.href || path
  if (href.indexOf('http') === 0) skipLocaleHandling = true
  if (locale && !skipLocaleHandling) {
    href = href
      ? `/${locale}${href}`
      : path?.replace('[locale]', locale)
  }

  return (
    <>
      <Link href={href} target={rest.target}>
        <div {...rest}>{children}</div>
      </Link>
    </>
  )
}

export default LinkComponent